
import './App.css';
// import Amplify from 'aws-amplify';
// import awsconfig from './aws-exports';

import React, {Component} from 'react';
import {BrowserRouter, Switch, Route, Link, HashRouter} from "react-router-dom";
import { Button, Navbar, Nav, NavDropdown, Form, FormControl, Badge, InputGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


import UrlParameter from './urlParameter';


//Amplify.configure(awsconfig)


// Class definition.
class App extends Component{
  
  render(){

   return(
      <div className="App">            
          {/* <BrowserRouter>
              <Route path="/:url" component={UrlParameter}></Route>
          </BrowserRouter> */}

          {/*Why we need HashRouter:    https://stackoverflow.com/questions/66326106/react-router-locations-on-aws-s3-bucket-do-not-work*/}
          <HashRouter>
              <Route path="/:url" component={UrlParameter}></Route>
          </HashRouter>
      </div>
    )
  }
}

export default App;
