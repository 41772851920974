// /* eslint-disable no-lone-blocks */


import './urlParameter.css';

import React, {Component} from "react";
import { Button, Container, Row, Col, Modal, Image, Card, ProgressBar, ButtonGroup, Spinner, Dropdown, DropdownButton, Alert, Fade,OverlayTrigger, Tooltip} from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';

import { IoNavigateCircleOutline } from "react-icons/io5";

//import '@google/model-viewer';
//import '@google/model-viewer/dist/model-viewer.min.js';
import '@google/model-viewer/dist/model-viewer';

import * as QrCode from 'qrcode.react';

import windowSize from 'react-window-size';
//import hdr_background from './components/abandoned_factory_canteen_02_1k.hdr'
//import hdr_background from './components/photo_studio_01_1k.hdr'
import hdr_background from './components/sidekix-media-BAVam-y_9Wg-unsplash.jpg'

import xenkia_logo_32x32 from './icon/xenkia32x32.png'
// import brightness_icon from './icon/brightness.png'
// import center_focus_icon from './icon/center-focus.png'
// import pause from './icon/pause.png'
// import play from './icon/play.png'
import brightness_icon from './icon/icons8/brightness.png'
import center_focus_icon from './icon/icons8/re-center.png'
import pause from './icon/icons8/pause.png'
import play from './icon/icons8/play.png'
import variants from './icon/icons8/variants-1.png'
import animation from './icon/icons8/animation-1.png'
import augment from './icon/icons8-ar-100.png'
import scanQR from './icon/icons8-scan-96.png'



class UrlParameter extends Component{

    state = {

              width                       : '640px', 
              height                      : '480px', 
              backgroundColor             : '#F4F7F9',
              exposurePercentage          : 50,
              play_pause_button_skin      : pause,
              play_pauseButtonState       : 'pause',
              full_address                : "",
              usdz_address                : "",
              qrCode_address              : "",
              poster_address              : "",
              friendlyAssetName           : "",
              infoObtainedFromServer      : false,
              matVariantListShow          : 'none',
              animationListShow           : 'none',
              dispAnimPlayPauseButton     : 'none',
              listOfVariants              : [],
              listOfAnimations            : [],
              selectedVariant             : "",

              // Progress Bar.
              loadingProgressBar          : 0,
              loadingProgressBarVisible   : false,
              loadingBarPosX              : 0,
              loadingBarPosY              : 0,

              // Spinner.
              spinnerPosX                 : 0,
              spinnerPosY                 : 0,

              modelViewerAutoplay         : "autoplay",

              show                        : false,
              showBrightnessLevel         : false,

              // url Params:
              DisplayName                 : false,
              AREnabled                   : false,
              ShowTagLine                 : false,
              ARPlacement                 : "floor",
              Animate                     : false,
              DefaultExposure             : 50,
              ZoomEnabled                 : false,
              QRCodeEnabled               : false
            };

    // URL.
    currentURL                  = "";

    // url Params:
    uuid                        = "";
    
    testVariants = ["Var1", "Var2"];

    modelViewer;


    // This is the default ModelViewer Exposure.
    buttonOpacity       = 50;

    constructor(props) {
        super(props);

        this.exposureControl          = this.exposureControl.bind(this);
        this.start_pause_animation    = this.start_pause_animation.bind(this);
    }

    


    UNSAFE_componentWillMount(){
      
        // Everytime the Window is Resized, thie event gets fired.
        window.addEventListener('resize', (event) =>{
          this.updateWindowHeightAndWidth();
        });
    }

    // As the Window gets resized by the user, the Model-Viewer should accomodate this.
    updateWindowHeightAndWidth(){
      // Set the initial Width and Height of the Window.
      this.setState({width: window.innerWidth.toString()+'px',   height: window.innerHeight.toString()+'px'});

      // Calcuate the Loading Bar's position.
      this.setState({
        // The Loading Bar is 25% of the Width of the Window.
        loadingBarPosX: (window.innerWidth - (window.innerWidth * 0.5))/2,
        loadingBarPosY: (window.innerHeight / 2)
      });

      // Calcuate the Spinner's position.
      this.setState({
        // The Spinner should be in the middle of the screen.
        spinnerPosX:    (window.innerWidth / 2),
        spinnerPosY:    (window.innerHeight / 2)
      });
    }


    // Do everything after all the components have been mounted.
    async componentDidMount(){

        const modelViewer = document.querySelector('#model');
        const tapDistance = 2;

        let panning = false;
        let panX, panY;
        let startX, startY;
        let lastX, lastY;
        let metersPerPixel;

        let assetDetails;


        // Store the current URL.
        this.currentURL = window.location.href;

        // Set the Resolution of the model-viewer.
        this.setState({width: this.props.windowWidth.toString()+'px',   height: this.props.windowHeight.toString()+'px'});

        // Initialize the Loading Bar's position.
        this.setState({
          // The Loading Bar is 25% of the Width of the Window.
          loadingBarPosX: (this.props.windowWidth - (this.props.windowWidth * 0.5))/2,
          loadingBarPosY: (this.props.windowHeight / 2)
        });

        // Initialize the Spinner's position.
        this.setState({
          // The Spinner should be in the middle of the screen.
          spinnerPosX:    (this.props.windowWidth / 2),
          spinnerPosY:    (this.props.windowHeight / 2)
        });

        //this.setState({width: window.innerWidth.toString()+'px',   height: window.innerHeight.toString()+'px'});
        //console.log(window.innerWidth, window.innerWidth);     

        // Logic for extracting the parameters. Check the following link to parse URL parameters.
        // Note: https://www.sitepoint.com/get-url-parameters-with-javascript/, https://stackoverflow.com/questions/8486099/how-do-i-parse-a-url-query-parameters-in-javascript
        //"http://localhost:3000/params=1&uuid=65f7132e-0aeb-54b8-9ec0-34f6200332d8&DisplayName=false&AREnabled=true&Animate=true&DefaultExposure=50&ShowTagLine=true"

        // Extract the parameters from the URL.
        {
          const urlParams = new URLSearchParams(window.location.href);

          if(urlParams.has('uuid'))
          {
            this.uuid =  urlParams.get('uuid');

            // Get rid of the '/' at the end if any.
            if (this.uuid.slice(-1) === '/')
            {
              this.uuid = this.uuid.slice(0, -1);
            }

            //console.log(this.uuid);
            //alert(this.uuid);
          }
          if(urlParams.has('DisplayName'))
          {
            this.setState({DisplayName:  (urlParams.get('DisplayName').toLowerCase() === 'true')});

            //console.log(this.DisplayName);
          }
          if(urlParams.has('AREnabled'))
          {
            this.setState({AREnabled:  (urlParams.get('AREnabled').toLowerCase() === 'true')});
            //console.log(this.AREnabled);
          }
          if(urlParams.has('Animate'))
          {
            this.setState({Animate :  (urlParams.get('Animate').toLowerCase() === 'true')});
            //console.log(this.Animate);
          }
          if(urlParams.has('DefaultExposure'))
          {
            this.setState({exposurePercentage:  Number(urlParams.get('DefaultExposure'))});
            //console.log(this.DefaultExposure);
          }
          if(urlParams.has('ShowTagLine'))
          {
            this.setState({ShowTagLine:  (urlParams.get('ShowTagLine').toLowerCase() === 'true')});
            //console.log(this.ShowTagLine);
          }
          if(urlParams.has('ARPlacement'))
          {
            this.setState({ARPlacement:  urlParams.get('ARPlacement').toLowerCase()});
            //console.log(urlParams.get('ARPlacement').toLowerCase());
          }
          if(urlParams.has('ZoomEnabled'))
          {
            this.setState({ZoomEnabled:  (urlParams.get('ZoomEnabled').toLowerCase() === 'true')});
            //console.log(this.ZoomEnabled);
          }
        }

        // Get settings from remote DB.
        {
            const urlParams   =   new URLSearchParams(window.location.href);
            const uuid        =   urlParams.get('uuid');

            if(urlParams.has('uuid'))
            {
              // Get rid of the '/' at the end if any.
              if (uuid.slice(-1) === '/')
              {
                uuid = uuid.slice(0, -1);
              }
            }


            // Get 3D-viewer settings from Remote DB
            var res = await fetch('https://17vpdulb4e.execute-api.us-east-1.amazonaws.com/prod/updatesettings', {
                method: 'POST',
                /* mode: 'no-cors', */
                body: JSON.stringify({
                    request:            "getViewerSettings",
                    asset_uuid:         uuid
                })
            });

            // Get the response from the Backend.
            var response = await res.json();
            
            // If the response is OK, then do the followings.
            if(response.statusCode === 200)
            {
                console.log(response.body.settings);

                if(response.body.settings.displayName === 'true') this.setState({DisplayName: true});
                else this.setState({DisplayName:  false});

                if(response.body.settings.displayTagline === 'true') this.setState({ShowTagLine: true});
                else this.setState({ShowTagLine:  false});

                this.setState({backgroundColor: response.body.settings.bkgrndColor});

                if(response.body.settings.enableDepth === 'true') document.getElementById("model").style.boxShadow = 'inset 0px -400px 400px -400px grey';
                else document.getElementById("model").style.boxShadow = 'none';

                document.querySelector('#model').variantName = response.body.settings.defaultVariant;
                this.setState({selectedVariant:  response.body.settings.defaultVariant});

                this.setState({exposurePercentage: Math.round(response.body.settings.defaultExposure * 10)});

                if(response.body.settings.enableQRCode === 'true') this.setState({QRCodeEnabled: true});
                else this.setState({QRCodeEnabled: false});

                // Enable/Disable AR Zoom.
                if(response.body.settings.enableZoom === 'true') this.setState({ZoomEnabled:  true});
                else this.setState({ZoomEnabled:  false});

                if(response.body.settings.enableAR === 'true') this.setState({AREnabled:  true});
                else this.setState({AREnabled:  false});
            }
          
        }



        /* const params    = test.split('?').slice(1);
        console.log(params); 
        console.log(params.toString()); */

        

        // Get the asset details. This is temporary.
        //assetDetails = await GetAssetDetails(this.props.location.pathname.split('/')[1]);
        //console.log(this.props.location.pathname.split('/')[1]);

        /* 
          Get Asset details.
        */
        // Incease the upload counter by 1 in the remote database..
        var res = await fetch("https://q5rygp0k2h.execute-api.us-east-1.amazonaws.com/prod/getdetails", {
          method: 'POST',
          //mode: 'no-cors',
          body: JSON.stringify({
              "request" :          "readDetails",
              //"uuid"    :          this.props.location.pathname.split('/')[1]
              "uuid"    :          this.uuid
          })
        });
        
        // Get the response from the Backend.
        var response = await res.json();
        
        //console.log(response);

        // Only update the Upload counter if the remote Upload counter was successfully incremented.
        if(response.statusCode === 200)
        {
              //console.log(this.Animate);
              //console.log(response.body.data.Item);


/*               // Based on the URL parameter, turn on/off animation.
              if(this.Animate === true) this.setState({modelViewerAutoplay : "autoplay "});
              else this.setState({modelViewerAutoplay : ""}); */


              // Prevent Right Mouse Button Click.
              document.addEventListener('contextmenu', (e) => {
                  e.preventDefault();
                });
                

              // Get the Friendly Asset Name.
              this.setState({friendlyAssetName: response.body.data.Item.friendlyAssetName});

              // Mark that the model specific information has been obtained from the server. This is needed to stop displaying the GREEN circle.
              this.setState({infoObtainedFromServer: true});


              // Load 3d asset related info from the remote server.
              this.setState({
                  // This is the location of the 3D Model to load from.
                  full_address:     response.body.data.Item.S3Url + response.body.data.Item.fileName + ".glb",
                  usdz_address:     response.body.data.Item.S3Url + response.body.data.Item.fileName + ".usdz",

                  qrCode_address:   response.body.data.Item.S3Url + "qr/qr_" + response.body.data.Item.fileName + ".png",

                  // Final address of the Poster for this model.
                  poster_address:   response.body.data.Item.S3Url + "POSTER_" + response.body.data.Item.fileName + ".png"
                });

              

              // Increase the View/Download count by 1.
              IncreaseViewCount(response.body.data.Item.email);

              // Increase the Assets View Count based on UUID.
              //IncreaseAssetViewCount(this.props.location.pathname.split('/')[1]);
              IncreaseAssetViewCount(this.uuid);
              
              // Download progress bar.
              // Note: https://www.gitmemory.com/issue/google/model-viewer/2302/824724336
              {
                modelViewer.addEventListener('progress', (event) => {
                  // Update the progress bar state.
                  this.setState({loadingProgressBar: event.detail.totalProgress * 100});

                  // Make the Progress Bar invisible, since it has been 100% loaded.
                  if((event.detail.totalProgress * 100) === 100) this.setState({loadingProgressBarVisible: false});
                  else this.setState({loadingProgressBarVisible: true});
                });
              }

              // Material Variants.
              const modelViewerVariants     = document.querySelector("model-viewer#model");
              const selectVariants          = document.querySelector('#variant');
              const selectAnimations        = document.querySelector('#animations');
              const reactDropdown          = document.querySelector('#variant-list');

              // Note: https://github.com/google/model-viewer/issues/1573
              modelViewer.addEventListener('load', () => {

                // Set the Play/Pause button skin based on the state of animation playback.
                {
                  if (modelViewer.paused) {
                      this.setState({play_pause_button_skin: play});
                  } else {
                      this.setState({play_pause_button_skin: pause});
                  }
                }

                // Load default exposure control.
                {
                    modelViewer.exposure = this.state.exposurePercentage / 25;
                    //console.log(modelViewer.exposure, this.state.exposurePercentage);
                }

                // MATERIAL VARIANTS.
                {
                    const names = modelViewer.availableVariants;
                    this.setState({listOfVariants: modelViewer.availableVariants});
                    //console.log(names, this.testVariants);

                    // The 'Variant' list should be shown when there are more than 1 material for the 3d model. 
                    if (names.length > 1) this.setState({matVariantListShow: 'block'});
                    else this.setState({matVariantListShow: 'none'});

                    // // Add the names of Material Variants to the Dropodown menu.
                    // for (const name of names) {
                    //   const matVariantOptions = document.createElement('option');

                    //   matVariantOptions.value = name;
                    //   matVariantOptions.textContent = name;
                    //   selectVariants.appendChild(matVariantOptions);

                    //   //reactDropdown.appendChild(matVariantOptions);
                    // }
                }

                // LIST OF ANIMATIONS.
                {
                    const animationList = modelViewer.availableAnimations;
                    this.setState({listOfAnimations: modelViewer.availableAnimations})
                    //console.log(animationList);

                    // Dispaly the Animation Play/Pause button only if the loaded 3d model at least has 1 animation in it.
                    if (animationList.length > 0) 
                    {
                      this.setState({dispAnimPlayPauseButton: 'block'});
                    }
                    else 
                    {
                      this.setState({dispAnimPlayPauseButton: 'none'});
                    }

                    // The 'Animation' list should be shown when there are more than 1 Animation to choose from for the 3d model.
                    if (animationList.length > 1) 
                    {
                      this.setState({animationListShow: 'block'});
                    }
                    else this.setState({animationListShow: 'none'});

                    // // Add the names of Animations to the Dropodown menu.
                    // for (const name of animationList) {
                    //   const animationOptions = document.createElement('option');

                    //   animationOptions.value = name;
                    //   animationOptions.textContent = name;
                    //   selectAnimations.appendChild(animationOptions);
                    // }
                }
              });

              // If anyone in the Model Viewer has been clicked, then do the followings.
              modelViewer.addEventListener("click", () => {
                
                this.setState({show: false});                                             // Hide the Brightness Bar.
              });

              // // Select the Material Variants based on the users preference.
              // selectVariants.addEventListener('input', (event) => {
              //   modelViewerVariants.variantName = event.target.value;
              // });

              // // Select the Animation to play, based on the users preference.
              // selectAnimations.addEventListener('input', (event) => {
              //   modelViewerVariants.animationName = event.target.value;
              // });


              // // ReactJs drop-down menu click event test.
              // reactDropdown.addEventListener('click', (event) => {
              //   event.target.addEventListener('click', (event) => {
              //     console.log(event.target.value);
              //   });
              // });

              // Panning related functions.
              const startPan = () => {
                  const orbit = modelViewer.getCameraOrbit();
                  const {theta, phi, radius} = orbit;
                  const psi = theta - modelViewer.turntableRotation;
                  metersPerPixel = 0.75 * radius / modelViewer.getBoundingClientRect().height;
                  panX = [-Math.cos(psi), 0, Math.sin(psi)];
                  panY = [
                    -Math.cos(phi) * Math.sin(psi),
                    Math.sin(phi),
                    -Math.cos(phi) * Math.cos(psi)
                  ];
                  modelViewer.interactionPrompt = 'none';
        
                  //console.log("start-pan");
                };
              
                const movePan = (thisX, thisY) => {
                  const dx = (thisX - lastX) * metersPerPixel;
                  const dy = (thisY - lastY) * metersPerPixel;
                  lastX = thisX;
                  lastY = thisY;
              
                  const target = modelViewer.getCameraTarget();
                  target.x += dx * panX[0] + dy * panY[0];
                  target.y += dx * panX[1] + dy * panY[1];
                  target.z += dx * panX[2] + dy * panY[2];
                  modelViewer.cameraTarget = `${target.x}m ${target.y}m ${target.z}m`;
              
                  // This pauses turntable rotation
                  modelViewer.dispatchEvent(new CustomEvent(
                        'camera-change', {detail: {source: 'user-interaction'}}));
        
                  //console.log("move-pan");
                };

              // Panning related Evenet Listeners.
              modelViewer.addEventListener('mousedown', (event) => {
                  startX = event.clientX;
                  startY = event.clientY;
                  panning = event.button === 2 || event.ctrlKey || event.metaKey ||
                      event.shiftKey;
                  if (!panning)
                    return;
              
                  lastX = startX;
                  lastY = startY;
                  startPan();
                  event.stopPropagation();
        
                  //console.log("mouse-down");
                }, true);
              
                modelViewer.addEventListener('touchstart', (event) => {
                  startX = event.touches[0].clientX;
                  startY = event.touches[0].clientY;
                  panning = event.touches.length === 2;
                  if (!panning)
                    return;
              
                  const {touches} = event;
                  lastX = 0.5 * (touches[0].clientX + touches[1].clientX);
                  lastY = 0.5 * (touches[0].clientY + touches[1].clientY);
                  startPan();
                }, true);
              
                modelViewer.addEventListener('mousemove', (event) => {
                  if (!panning)
                    return;
              
                  movePan(event.clientX, event.clientY);
                  event.stopPropagation();
                }, true);
              
                modelViewer.addEventListener('touchmove', (event) => {
                  if (!panning || event.touches.length !== 2)
                    return;
              
                  const {touches} = event;
                  const thisX = 0.5 * (touches[0].clientX + touches[1].clientX);
                  const thisY = 0.5 * (touches[0].clientY + touches[1].clientY);
                  movePan(thisX, thisY);
                }, true);

                modelViewer.addEventListener('mouseup', (event) => {
                  panning = 0;
                }, true);

        }
        else
        { 
            // Mark that the model specific information could not be loaded.
            this.setState({infoObtainedFromServer: false});

            console.log("Error: ", response.statusCode);
        }
    }

    // This will Re-center the model.
    recenterModel()
    {
        const modelViewer = document.querySelector("#model");

        //modelViewer.cameraTarget= `${0}m ${0.5}m ${0}m`;
        modelViewer.cameraTarget= `auto auto auto`;
        //modelViewer.fieldOfView= 
        //modelViewer.position = ;

        //console.log("recenter");
    }


    // We will change the exposure of the Model-Viewer here.
    exposureControl(sliderChangeEvent)
    {
        let modelViewer = document.querySelector('#model');

/*         // Cycle exposure between 25% and 100% based on the users preference.
        if(this.state.exposurePercentage < 100) this.setState({exposurePercentage: (this.state.exposurePercentage+25)});
        else this.setState({exposurePercentage: 25});

        // Update the model-viewer exposure value [1-4].
        modelViewer.exposure = this.state.exposurePercentage / 25;

        console.log(modelViewer.exposure, this.state.exposurePercentage); */

        // Cycle exposure between 1 and 4 based on the users preference.
        // if(modelViewer.exposure < 4)
        // {
        //     modelViewer.exposure++;
        // }
        // else
        // {
        //     modelViewer.exposure = 1;
        // }

        modelViewer.exposure            = sliderChangeEvent / 25;

        modelViewer.shadowIntensity     = 2 - sliderChangeEvent / 50;

        // This will help display the percentage of exposure.
        // Note: https://stackoverflow.com/questions/32317154/react-uncaught-typeerror-cannot-read-property-setstate-of-undefined.
        this.setState({exposurePercentage: Math.round((modelViewer.exposure * 25))});

        //console.log(modelViewer.exposure);
    }


    // This function will Start/Pause the Animation.
    start_pause_animation()
    {
        let modelViewer             = document.querySelector('#model');

        //console.log(modelViewer.childNodes);

        if (modelViewer.paused) {
            modelViewer.play();
            this.setState({play_pause_button_skin: pause, play_pauseButtonState: 'pause'});
        } else {
            modelViewer.pause();
            this.setState({play_pause_button_skin: play, play_pauseButtonState: 'play'});
        }
        
    }


    // This function will show notification for some time before disappearing.
    showNotification() {
        
        // You can use redux for this.
        this.setState({
          show: true,
        });

        // setTimeout(() => {
        //   this.setState({
        //     show: false,
        //   });
        // }, 3000);

        //console.log("Show notification");

    }



    render()
    {   
          const modelViewer = document.querySelector('#model');

          return(
                <model-viewer
                    id                      = 'model'
                    environment-image       = {hdr_background}
                    shadow-intensity        = "1"
                    shadow-softness         = "1"
                    //style                   = {this.state}
                    style                   = {{width: this.state.width, height: this.state.height, backgroundColor: this.state.backgroundColor}}
                    src                     = {this.state.full_address}
                    ios-src                 = {this.state.usdz_address}
                    poster                  = {this.state.poster_address}
                    alt                     = "3d model"
                    auto-rotate
                    camera-orbit            = "auto 65deg 2.5m"
                    field-of-view           = "30deg"
                    min-field-of-view       = "30deg"
                    max-field-of-view       = "120deg"
                    camera-controls
                    

                    // This part Enables/Disables the AR functionality.
                    {...(this.state.AREnabled ? {ar: true} : {})}
                    
                    // Values between [1-4] is accepted.
                    exposure                = {this.state.exposurePercentage / 25}
                    autoplay                
                    
                    ar-placement            = {this.state.ARPlacement}
                    //ar-placement            = "wall"
                    ar-scale                = {this.state.ZoomEnabled ? "auto" : "fixed"}
                    ar-modes                = "webxr scene-viewer quick-look"
                >

                    {this.state.DisplayName
                      ? <h3 style={{fontSize: '2rem', textShadow: "1px 1px 5px #C7C7C7"}}>
                          <br/>
                          <b >
                              {this.state.friendlyAssetName}
                          </b>
                        </h3>
                      : <> </>
                    }

                    
                    {this.state.matVariantListShow === 'block'
                      ?
                        <p style={{fontFamily: 'Open Sans', fontWeight: 300, fontSize: '1.2rem', textShadow: "3px 3px 10px #C7C7C7"}}> <b>Variant:</b> {this.state.selectedVariant} </p>
                      :
                        <> </>
                    }

                    {this.state.ShowTagLine
                      ? <h6 style={{position: 'absolute', left: '3%', bottom: '1%', color: 'grey'}}> <br/> <Image src={xenkia_logo_32x32}/> <i style={{color: '#555555'}}>Powered by</i> <a href="https://www.xenkia.com/" target="_blank" rel="noreferrer"> Xenkia, Inc.</a>  <i style={{color: '#555555'}}>&#169;{new Date().getFullYear()}</i> </h6>
                      : <> </>
                    }


                    {this.state.AREnabled
                      ? 
                        // Pulsating AR Button: https://www.florin-pop.com/blog/2019/03/css-pulse-effect/
                        // <img slot="ar-button" src={augment} height="32px" alt="augment" style={{position: 'absolute', right: '4%', bottom: '4%', borderRadius: '50%'}}></img>
                        <img className='ar-button-class' slot="ar-button" src={augment} height="32px" alt="augment-button"></img>
                        // <img className='ar-button-class' src={augment} height="32px" alt="augment-button"></img>
                      : 
                        <> </>
                    }



                    {/* <button slot="ar-button" id="ar-button">
                        View in your space
                    </button> */}

                    {this.state.loadingProgressBarVisible
                      ? <Alert style={{position: 'absolute', top: this.state.loadingBarPosY, left: this.state.loadingBarPosX, width: '50%'}} variant='light'>
                          <b> Loading... </b>
                          <ProgressBar slot="progress-bar"  animated now={this.state.loadingProgressBar} label={`${Math.round(this.state.loadingProgressBar)}%`}/>
                        </Alert>
                      : <> </>
                    }

                    {this.state.infoObtainedFromServer
                      ? <> 
                        </>
                      : <>
                          <Spinner style={{position: 'absolute', top: this.state.spinnerPosY, left: this.state.spinnerPosX}} animation="border" variant="success"/>
                        </>
                    }


                    
                    {/**
                     * This section of the Button Panel.
                     */}
                    <div className="panel panel-pos">
                    {/* <ButtonGroup className="button-group-position" horizontal aria-label="vertical-menu"> */}
                        <OverlayTrigger placement="bottom" overlay={<Tooltip>Ambient light</Tooltip>}>
                          <img className="panelButton" src={brightness_icon} alt="brightness" onClick={() => this.showNotification()}/>
                        </OverlayTrigger>

                        <OverlayTrigger placement="bottom" overlay={<Tooltip>Re-center model</Tooltip>}>
                          <img className="panelButton" src={center_focus_icon} alt="center-focus" onClick={() => this.recenterModel()}/>
                        </OverlayTrigger>
                        
                        {(this.state.matVariantListShow==='block')
                        ?
                          <OverlayTrigger placement="bottom" overlay={<Tooltip>Select variant</Tooltip>}>
                            <DropdownButton
                                as        =   {ButtonGroup}
                                key       =   'up'
                                id        =   'variant-list'
                                drop      =   'up'
                                variant   =   "flat"
                                title     =   {<img className="panelButton" src={variants} alt="variants"/>}
                                disabled  =   {this.state.matVariantListShow==='none' ? true : false}
                                //style     =   {{display: this.state.matVariantListShow}}
                            >
                              {this.state.listOfVariants.map(val => (
                                // Note: https://blog.logrocket.com/react-icons-comprehensive-tutorial-examples/
                                <Dropdown.Item {...(this.state.selectedVariant===val ? {active: true} : {active: false})} onClick={() => {
                                    modelViewer.variantName = val;
                                    this.setState({selectedVariant: val});
                                  }
                                }> <IoNavigateCircleOutline/> {val}
                                </Dropdown.Item>
                              ))}
                            </DropdownButton>
                          </OverlayTrigger>
                        :
                          <> </>
                        }

                        {(this.state.dispAnimPlayPauseButton==='block')
                        ?
                          <Fade in={this.state.dispAnimPlayPauseButton==='none' ? true : false}>
                            <OverlayTrigger placement="bottom" overlay={<Tooltip>Select animation</Tooltip>}>                              
                              <DropdownButton
                                  as        =   {ButtonGroup}
                                  key       =   'up'
                                  id        =   'animation-list'
                                  drop      =   'up'
                                  variant   =   "flat"
                                  title     =   {<img className="panelButton" src={animation} alt="variants"/>}
                                  disabled  =   {this.state.animationListShow==='none' ? true : false}
                                  //style     =   {{display: this.state.animationListShow}}
                              >
                                {this.state.listOfAnimations.map(val => (
                                  // Note: https://blog.logrocket.com/react-icons-comprehensive-tutorial-examples/
                                  <Dropdown.Item onClick={() => {
                                    modelViewer.animationName = val;
                                  }
                                }> <IoNavigateCircleOutline/> {val} </Dropdown.Item>
                                ))}
                              </DropdownButton>
                            </OverlayTrigger>
                          </Fade>
                        :
                          <> </>
                        }
                        
  
                        {(this.state.dispAnimPlayPauseButton==='block')
                          ?
                            <Fade in={this.state.dispAnimPlayPauseButton==='none' ? true : false}>
                              <OverlayTrigger placement="bottom" overlay={this.state.play_pauseButtonState==='play' ? <Tooltip>Play animation</Tooltip> : <Tooltip>Pause animation</Tooltip>} >
                                <img className="panelButton" src={this.state.play_pause_button_skin} alt="play-pause" style={{pointerEvents: 'auto'}} onClick={() => this.start_pause_animation()}/>
                              </OverlayTrigger>
                            </Fade>
                          :
                            // <img className="panelButton" src={this.state.play_pause_button_skin} alt="play-pause" style={{pointerEvents: 'none', opacity: '50%'}} onClick={() => this.start_pause_animation()}/>
                            <> </>
                        }


                    {/* </ButtonGroup> */}
                    </div>
                        
                    


                    {/* {this.state.matVariantListShow
                      ? <select id="variant" style={{left: '50%', top: '3%', position: 'absolute'}}> </select>
                      : <> </>
                    } */}

                    <Row>
                      {/* <Col>
                        <p style={{marginTop: '40px', marginLeft: 'auto', marginRight: 'auto', display: this.state.matVariantListShow}}> Variants: <select id="variant"> </select> </p>
                      </Col> */}
                      {/* <Col>
                        <p style={{marginTop: '40px', marginLeft: 'auto', marginRight: 'auto', display: this.state.animationListShow}}> Animations: <select id="animations"> </select> </p>
                      </Col> */}
                    </Row>

                    {/* If the Window Width is greater then 1024 pixels, only then display the QR code. */}
                    {(window.innerWidth >= 1024) && this.state.QRCodeEnabled
                      ? <div style={{position: 'absolute', bottom: '5%', right: '3%'}}>
                          {/* <Image style={{width: '96px', height: '96px'}} src="https://xenkia55844-vscode.s3-accelerate.amazonaws.com/3d-models/qr/qr_vintage-camera.png"/> */}
                          {/* <Image style={{width: '128px', height: '128px'}} src={this.state.qrCode_address}/> */}
                          <OverlayTrigger placement="top" overlay={<Tooltip>Scan this QR code from your Phones/Tablets camera application</Tooltip>}>
                            {/* Note: https://zpao.github.io/qrcode.react/ */}
                            <QrCode 
                                value={window.location.href} 
                                size={128} 
                                id="qrCode" 
                                includeMargin={true} 
                                level='Q'
                                renderAs={"svg"}
                                imageSettings={{
                                  src:      scanQR,
                                  x:        null,
                                  y:        null,
                                  height:   24,
                                  width:    24,
                                  excavate: true,
                                }}/>
                          </OverlayTrigger>
                          {/* {console.log(this.state.qrCode_address)} */}
                          <p style={{color: '#003CC1', fontSize: '16px', textShadow: '1px 1px 2px #4d9aff'}}> Scan to view in AR </p>
                        </div>
                      : <> </>
                    }

                    {/* This part of the code will display message after any of the button have been used */}
                    {
                        //<span className={this.state.show ? 'show' : ''}>Saved!</span>
                        // <div>
                        //   <span className={this.props.show ? 'show' : ''} style={{position: 'absolute', display: this.state.show? 'block' : 'none', bottom: '5%', left: '50%'}}> Brightness: {this.state.exposurePercentage}% </span>
                        // </div>
                    }

                    {/* This section will real with the Brightness slider, Example:  https://jaywilz.github.io/react-bootstrap-range-slider/  */}
                    {
                      <div style={{display: this.state.show? 'block' : 'none'}}>
                        <div className="ambient-light-slider">
                          {/* <text className="brightness-value brightness-value-pos brightness-value-font-size"> <i> {this.state.exposurePercentage}%  </i> </text> */}
                          <text className="brightness-value"> <i> {this.state.exposurePercentage}%  </i> </text>
                          <br/>
                          <RangeSlider type="range" style={{minWidth: '97%'}} tooltipPlacement='up' tooltipLabel={currentValue => <b>{currentValue}%</b>} tooltip='off' value={this.state.exposurePercentage} defaultValue={this.state.exposurePercentage} onChange={changeEvent => this.exposureControl(changeEvent.target.value)}/>
                        </div>
                      </div>
                    }

                    {/*This section  prints the Brightness value */}
                    {
                        // <text className="brightness-value brightness-value-pos brightness-value-font-size" style={{display: this.state.show? 'block' : 'none'}}> <i> Ambient light: {this.state.exposurePercentage}%  </i> </text>
                    }

                </model-viewer>
          );
    }
};


/**
 * 3d Viewer Function.
 */
 async function IncreaseViewCount(email){ 
 
   // Increase the view count.
   {
       // Incease the upload counter by 1 in the remote database..
       var res = await fetch("https://oidnlpynvl.execute-api.us-east-1.amazonaws.com/prod/increaseviewcount", {
         method: 'POST',
         //mode: 'no-cors',
         body: JSON.stringify({
             "email":                email,
             "field_to_update":      "download_counter",
             "value":                1,
             "inc_dec_reset":        "inc"
         })
       });
 
       // Get the response from the Backend.
       var response = await res.json();
 
       // Only update the Upload counter if the remote Upload counter was successfully incremented.
       /* if(response.statusCode === 201)
       {
           console.log("Status Code: 201.");
       }
       else console.log(response); */
   }
 
 }


 /**
 * Increase Asset View Count based on UUID.
 */
  async function IncreaseAssetViewCount(uuid){
  
    // Increase the Assets Local View Count.
    {
        // Incease the upload counter by 1 in the remote database..
        var res = await fetch("https://q5rygp0k2h.execute-api.us-east-1.amazonaws.com/prod/getdetails", {
          method: 'POST',
          //mode: 'no-cors',
          body: JSON.stringify({
              "request" :          "increaseAssetViewCount",
              "uuid"    :          uuid
          })
        });
  
        // Get the response from the Backend.
        var response = await res.json();
  
        // Only update the Upload counter if the remote Upload counter was successfully incremented.
        if(response.statusCode === 201)
        {
            //console.log("Response Code: 201, View Count successfully increased!", response);
            return response;
        }
    }
  
  }

 /**
 * Get Asset Details based on UUID.
 */
  async function GetAssetDetails(uuid){
  
    // Get Asset details.
    {
        // Incease the upload counter by 1 in the remote database..
        var res = await fetch("https://q5rygp0k2h.execute-api.us-east-1.amazonaws.com/prod/getdetails", {
          method: 'POST',
          //mode: 'no-cors',
          body: JSON.stringify({
              "request" :          "readDetails",
              "uuid"    :          uuid
          })
        });
        
        // Get the response from the Backend.
        var response = await res.json();
  
        // Only update the Upload counter if the remote Upload counter was successfully incremented.
        if(response.statusCode === 200)
        {
            console.log("Response Code: 200, Asset details successfuly read!", response.body.data.Item);
            return response;
        }
    }
  
  }

export default windowSize(UrlParameter);
//export default UrlParameter;